import React from "react";
import { graphql, Link } from "gatsby";
import DefaultLayout from "components/layout/default";
import layoutStyle from "styles/layout.module.css";
import radiusTopCapImage from "images/product-radius-top-cap.svg";
import radiusBottomCapImage from "images/product-radius-bottom-cap.svg";
import radiusCapBlackTopSmImage from "images/radius-cap-black-top-sm.svg";
import radiusCapBlackBottomSmImage from "images/radius-cap-black-bottom-sm.svg";
import featureImage1 from "images/compliance/compliance-Image01.svg";
import penTestImage2 from "images/compliance/compliance-Image02.svg";
import featureLogos from "images/compliance/compliance-Standards.svg";
import featureLogo from "images/compliance/compliance-MainLogo.png";
import iconImage1 from "images/compliance/compliance-Icon01.svg";
import iconImage2 from "images/compliance/compliance-Icon02.svg";
import iconImage3 from "images/compliance/compliance-Icon03.svg";
import iconImage4 from "images/compliance/compliance-Icon04.svg";
import iconImage5 from "images/compliance/compliance-Icon05.svg";
import iconImage6 from "images/compliance/compliance-Icon06.svg";
import Img from "components/image";
import { IMAGE_BACKGROUND_PROPS } from "constants/index";
import style from "styles/pages/products.module.css";
import styles from "styles/pages/solutions.module.css";

function PenetrationTestPage(props) {
  let [mainImageLoaded, setMainImageLoaded] = React.useState(false);
  let mainImageRef = React.useRef();

  React.useEffect(() => {
    setMainImageLoaded(mainImageRef.current.complete);
  }, [mainImageRef]);

  function handleMainImageLoad() {
    setMainImageLoaded(true);
  }

  function renderVerticalLine() {
    let height = "0%";
    if (mainImageLoaded) height = "64vh";
    return (
      <div
        className={`${style.verticalLine} absolute top-0 w-px bg-white hidden md:block`}
        style={{ height }}/>
    );
  }

  function renderSection1() {
    return (
      <section className="relative min-h-screen">
        <div className="container mx-auto px-8 pt-4 pb-16 md:py-32 z-10 relative">
          <div className="text-center px-8 md:px-0">
            <img
              ref={mainImageRef}
              className="inline-block pointer-events-none"
              src={featureLogo}
              width={340}
              alt=""
              onLoad={handleMainImageLoad}/>
          </div>
          <div className="relative flex">
            {renderVerticalLine()}
            <div className="md:w-1/2 flex-none"/>
            <div className="ml-0 md:ml-16">
              <h3 className="font-bold text-4xl lg:text-6xl leading-tight mt-4">
                Compliance
              </h3>
              <p>
                Demonstrate compliance with the world’s leading cybersecurity
                and privacy standards.
              </p>
            </div>
          </div>
        </div>
        <Img
          {...IMAGE_BACKGROUND_PROPS}
          objectFit="contain"
          alt="Background"
          fluid={props.data.backgroundImage.childImageSharp.fluid}/>
      </section>
    );
  }

  function renderSection2() {
    const subHeaderText = (
      <h3 className="font-serif italic text-2xl sm:text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
        Evaluate Your Business Systems With Horangi’s Security-First Approach
      </h3>
    );
    return (
      <section>
        <div className="container mx-auto px-8 pt-32 lg:py-0 relative text-black">
          {subHeaderText}
        </div>
      </section>
    );
  }

  function renderSection3() {
    return (
      <section className="relative bg-white text-black flex justify-center text-black bg-white">
        <div className="flex flex-wrap container pt-40">
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage1}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight uppercase">
                LEVERAGE OUR SECURITY EXPERTISE
              </h2>
              <p className="mt-2">
                Our technical security services fulfill requirements for
                compliance with well- known cybersecurity laws and frameworks.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage2}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight uppercase">
                Single point of contact
              </h2>
              <p className="mt-2">
                Our experienced consultants advise you along every step of the
                way, preparing you for certification audits and processes.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage3}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight uppercase">
                Multi-industry expertise
              </h2>
              <p className="mt-2">
                Horangi’s compliance solutions are trusted by compliance staff
                from organizations of all sizes and industries in Asia.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage4}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight uppercase">
                Integrated compliance solution
              </h2>
              <p className="mt-2">
                Horangi’s products monitor vulnerabilities and compliance
                requirements around the clock.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage5}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight uppercase">
                Risk assessment report
              </h2>
              <p className="mt-2">
                Our certified consultants evaluate your existing practices and
                report non-compliant findings on our platform.
              </p>
            </div>
          </div>
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage6}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight uppercase">
                Cyber roadmap
              </h2>
              <p className="mt-2">
                Prioritize the non-compliant issues to fix and track the
                progress of your compliance journey.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function renderSection4() {
    return (
      <section className="relative bg-white p-20 md:p-64 mb-40 mt-20">
        <p className="text-black font-bold text-base md:text-xl text-center mb-5">
          SUPPORTED STANDARDS
        </p>
        <img src={featureLogos} className="mx-auto" alt=""/>
      </section>
    );
  }

  function renderSection7() {
    return (
      <section className="relative text-white sm:mb-32">
        <img
          src={radiusCapBlackTopSmImage}
          alt="Background"
          className={`w-full z-1 pointer-events-none relative ${styles.complianceRadiusBlackCap}`}/>
        <div className="bg-black relative z-10 pb-16 sm:pb-0 mt-0 sm:-mt-px">
          <div className="container mx-auto px-8 pt-40 md:pt-16 md:pb-64 md:pt-32 z-10 relative">
            <div className="sm:flex">
              <div className="sm:w-3/5 mt-64 md:-mt-20 mb-40 ml-0 md:ml-10">
                <h2 className="font-bold text-xl leading-tight uppercase">
                  THE ONLY VENDOR YOU NEED
                </h2>
                <h3 className="font-serif italic text-4xl lg:text-6xl leading-tight mt-4 whitespace-pre-wrap">
                  Tackle Security And Compliance Together
                </h3>
                <p className="mt-8 mb-5">
                  As your security partner, we offer both the technical security
                  services as well as the consultation required in the
                  compliance journey. Horangi is committed to helping your
                  organization take advantage of transparency and a robust
                  security posture to build an innovative culture that your
                  stakeholders can trust.
                </p>

                <Link className="btn btn-orange" to="/solutions">
                  View Solutions
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.computerImage}>
            <img
              src={featureImage1}
              className={styles.complianceImage1}
              alt=""/>
          </div>

          <div className={styles.complianceFeature2}>
            <div
              className={`container mx-auto px-8 pt-10 md:pt-32 z-10 relative ${styles.feature2Wrapper}`}>
              <div className="sm:flex justify-end">
                <div className="sm:w-1/2 pl-0 md:pl-10">
                  <h2 className="font-bold text-xl leading-tight uppercase">
                    A TECH-DRIVEN COMPLIANCE ROADMAP
                  </h2>
                  <h3 className="font-serif italic text-4xl lg:text-6xl leading-tight mt-4 whitespace-pre-wrap">
                    Get Ahead With Horangi’s Enterprise-Grade Tools And Support
                  </h3>
                  <p className="mt-8 mb-16">
                    Leverage Horangi’s dedicated software and veteran
                    cybersecurity consultants to achieve your compliance goals
                    efficiently. From gap analysis to remediation, Horangi
                    creates a tailored roadmap and provides organization-wide
                    insights to help you drive meaningful impact on both your
                    compliance and security posture.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <img
                src={penTestImage2}
                alt=""
                style={{ width: "100%" }}
                className={`absolute ${styles.complianceImage2}`}/>
            </div>
          </div>
        </div>
        <img
          src={radiusCapBlackBottomSmImage}
          alt="Background"
          className="w-full z-1 pointer-events-none relative bg-white mt-0 sm:-mt-px"/>
      </section>
    );
  }

  function renderSection8() {
    return (
      <section className={`relative text-black ${styles.complianceContactUs}`}>
        <div className={`container mx-auto z-10`}>
          <h3 className="text-lg text-center leading-tight font-bold uppercase md:mb-2">
            Speak to a Horangi Tiger today
          </h3>
          <p
            className={`max-w-2xl mx-auto text-center whitespace-pre-wrap p-5 md:p-0`}>
            Get started with a complimentary consultation on how you can kick
            off your compliance process to today’s most recognized regulations
            and frameworks.
          </p>
          <div className="text-center">
            <a href={"/contact"} className="btn btn-orange mt-6 inline-block">
              Contact Us
            </a>
          </div>
        </div>
      </section>
    );
  }

  return (
    <DefaultLayout
      location={props.location}
      title="Horangi Compliance"
      description="Evaluate your business systems with Horangi’s security-first approach. Demonstrate compliance with the world’s leading cybersecurity and privacy standards.">
      <div className={layoutStyle.headerPlaceholder}/>
      {renderSection1()}
      <img
        src={radiusTopCapImage}
        alt="Background"
        className="w-full z-1 pointer-events-none"/>
      <div className="relative z-10 -mt-32 overflow-hidden">
        <div
          className="bg-white absolute w-full"
          style={{ top: "127px", bottom: "127px" }}/>
        {renderSection2()}
        {renderSection3()}
        {renderSection4()}
        {renderSection7()}
        {renderSection8()}
      </div>
      <img
        src={radiusBottomCapImage}
        alt="Background"
        className="w-full z-1 -mt-32 pointer-events-none"/>
      <div className="pb-48"/>
    </DefaultLayout>
  );
}

export default React.memo(PenetrationTestPage);

export const QUERY = graphql`
  query SolutionsComplianceQuery {
    pageContent: file(
      relativePath: { eq: "solution-pages/compliance.json" }
    ) {
      childProductPagesJson {
        title
        description
      }
    }
    backgroundImage: file(
      relativePath: { eq: "background-products-item.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
